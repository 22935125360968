import Image from 'next/legacy/image';
import React from 'react';

const NotFound = ({ message }) => {
	return (
		<div className="empty_page_wrapper">
			<div className="container">
				<div className="empty_card">
					<div className="empty_card_image">
						<Image src="/images/empty.png" layout="fill" objectFit="cover" objectPosition="center" alt="Picture" />
						<p className="empty_card_title">{message ? message : 'Oops!! Page Not Found'}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
